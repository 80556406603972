body {
  margin: 0;
  font-family: 'Amino';
  overflow: hidden;
}

@font-face {
  font-family: "Amino";
  src: url("../fonts/Amino\ Medium.ttf");
}
